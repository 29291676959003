import React, { useEffect } from 'react';
import { createContext, useState } from 'react';

type Dimensions = {
  width: number;
  height: number;
};

export const DimensionContext = createContext<Dimensions>({
  width: null,
  height: null,
});

export const DimensionProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [windowSizes, setWindowSizes] = useState<Dimensions>({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const detectWindowSize = () => {
    setWindowSizes({ width: window.innerWidth, height: window.innerHeight });
  };

  useEffect(() => {
    window.addEventListener('resize', detectWindowSize);

    return () => {
      window.removeEventListener('resize', detectWindowSize);
    };
  }, []);

  return (
    <DimensionContext.Provider value={windowSizes}>
      {children}
    </DimensionContext.Provider>
  );
};
